import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)
  const aboutme = getImage(data.aboutme)

 

  return (
    <Layout {...props}>
      <Seo title='About Me' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
          />
         
		<Divider />
          <Box sx={styles.imageWrapper}>
            
				{/** <Button as={GLink} to='/contact' sx={styles.button}>
              Contact Me
            </Button>
				*/}	
          </Box>
		 <Img image={aboutme} />
          <Divider />
		  
		   <Section title='👨‍💼 My Story'>
                <Text variant='p'>
                  Hi All, I am Aby Thannikal Joseph! I currently work for Cognizant Technology Solutions as a Test Automation Engineer.
				  <Box sx={styles.imageWrapper}>
          </Box>
                </Text>
                <Text variant='p'>
                My primary interests include Automation (mostly because I am bored of repeated manual tasks that  a machine can better do) or perhaps I find it more interesting to teach a machine how to do them.
 <Text variant='p'>
 I hold a degree in Mechatronics Engineering as I wanted to build and program things and felt like the perfect intersection of interests back in the day.
  </Text>
                </Text>
                <Text variant='p'>
                  This is my personal blog where I hope to document and share my learning and experiences (and experiments) to help the community of people having similar interests as mine.
                </Text>
                <Text variant='p'>
                 Having re-read an article about a <Text as={GLink} to='http://matrixcube.blogspot.com/p/facebook-chat-emoticons-software.html'>utility</Text> that I wrote when I was 17, sparked a thought that maybe I should just continue doing it.
Happy even if a handful of people get benefitted from it!
                </Text>
              
                <Text variant='p'>
                 All feedback are much appreciated and is always welcomed, You can DM me on <Text as={GLink} to='https://uk.linkedin.com/in/abytjoseph'>LinkedIN</Text> with your comments and suggestions.
                </Text>

              </Section>
			  
			  <Divider />
			  
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='🚀 Interests'>
                <Card variant='paper'>
                 <Text variant='p'>
                   <li> Ideation, Rapid Prototyping and Building innovative technical accelerators </li>
                 </Text>
				 <Text variant='p'>
                   <li>Implementing solutions that reduces time </li>
                 </Text>
				 <Text variant='p'>
                   <li> Participating in community learning sessions, technical conferences and workshops </li>
                 </Text>
                </Card>
              </Section>
			   <Divider/>
            </Box>
			
            <Box sx={styles.column}>
			
              <Section title='📃 Certifications'>
                <Card variant='paper'>
				  <Img image={image} />
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )

}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/certifications.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 595
          height: 420
          transformOptions: { cropFocus: NORTH }
        )
      }
    },
	aboutme: file(absolutePath: { regex: "/about-me-01.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1940
          height: 1293
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`

